<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-2xl font-bold">Terminal Retrieval</h1>
      <div class="flex justify-end items-end">
        <button
          class="bg-blue-600 py-3 px-6 rounded-md text-white"
          @click="downloadCSV"
        >
          Download CSV
        </button>
      </div>
      <div class="flex items-center mt-10">
        <input
          type="text"
          class="inline-block px-2 ring-2 outline-none ring-blue-500 rounded-sm ml-2 w-64 text-sm py-2"
          name=""
          placeholder="Search Merchant name or support officer"
          id=""
          v-model="search"
        />
        <button
          class="bg-blue-600 py-3 ml-2 px-6 rounded-md text-xs text-white"
          @click="searchRecords"
          :disabled="search == ''"
        >
          Search
        </button>
        <button
          class="bg-red-600 py-3 ml-1 px-6 rounded-md text-xs text-white"
          @click="clearSearchRecords"
          :disabled="search == ''"
        >
          Clear
        </button>
      </div>
      <div class="flex bg-blue-50 p-5 mt-10">
        <div class="flex-2 inline-block time-filter">
          <p>From:</p>
          <input
            type="date"
            class="inline-block border-2 h-10 px-2 rounded-sm mr-2"
            name=""
            id=""
            v-model="dateData.startDate"
          />
        </div>
        <div class="flex-2 inline-block time-filter">
          <p>To:</p>
          <input
            type="date"
            class="inline-block border-2 h-10 px-2 rounded-sm mr-2"
            name=""
            id=""
            v-model="dateData.endDate"
          />
        </div>
        <div class="flex mt-5">
          <button
            @click="fetchDatedRecords"
            class="block uppercase shadow bg-green-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs h-10 px-3 rounded"
          >
            Filter
          </button>
          <button
            @click="clearDateFilter"
            class="block uppercase shadow bg-red-800 hover:bg-red-700 ml-2 focus:shadow-outline focus:outline-none text-white h-10 text-xs px-3 rounded"
          >
            Reset
          </button>
        </div>
      </div>
      <div class="mt-4 flex bg-gray-50 p-5">
        <div class="block mt-4 mr-3">
          <select
            required
            v-model="filterType"
            class="shadow h-8 appearance-none border rounded w-full px-2 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
          >
            <option value="">Select Filter Type</option>
            <option value="admin">Admin</option>
            <option value="mso">MSO</option>
          </select>
        </div>
        <div class="block">
          <button
            class="inline-block bg-blue-900 text-white py-2 px-3 mt-4 rounded-md mr-2"
            :disabled="filterType == ''"
            @click="filterFunc"
          >
            Filter
          </button>
        </div>
        <div class="block">
          <button
            class="inline-block drop-shadow-2xl bg-yellow-200 text-dark py-2 px-3 mt-4 rounded-md mr-2"
            @click="clearFilterFunc"
            :disabled="filterType == ''"
          >
            Clear Filter
          </button>
        </div>
      </div>
      <div class="mt-14">
        <div class="mt-5" style="max-height: 600px; overflow: auto">
          <table class="shadow-lg bg-white w-full relative border-collapse">
            <tr>
              <th
                class="text-l p-3 text-left"
                colspan="23"
                style="background: #dee6f2"
              >
                Merchant Information
              </th>
            </tr>
            <tr>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Business Name
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Business ID
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Business Email
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Business Address
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Business Phone
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Terminal Type
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Serial Number
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Retrieval Date
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Retrieval Reason
              </th>
            </tr>
            <tr class="text-xs" v-for="(account, i) in filterAccounts" :key="i">
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.business_name || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.business_id || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.business_email || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.business_address || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.business_phone || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.terminal_type || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.serial_no || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{
                  moment(account.createdAt).format("DD MMM YYYY - h:mm:ss a")
                }}
              </td>

              <td class="border px-8 py-3  text-xs">
                {{ account.retrieval_reason || "N/A" }}
              </td>
            </tr>
          </table>
        </div>
        <div class="mt-3">
          <button
            @click="prevPage(currentPage)"
            :disabled="currentPage === 1 ? isDisabled : !isDisabled"
            class="uppercase shadow ml-3 hover:bg-blue-600 focus:shadow-outline focus:outline-none text-xs font-bold py-3 px-8 rounded"
            :class="
              currentPage === 1
                ? 'bg-blue-50 text-black'
                : 'bg-blue-900 text-white'
            "
          >
            &laquo; Prev
          </button>

          <button
            @click="nextPage(currentPage)"
            class="float-right uppercase shadow ml-3 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
            v-if="filterAccounts.length > 99"
          >
            Next &raquo;
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      search: "",
      accounts: null,
      currentPage: 1,
      isDisabled: true,
      dateData: null,
      dateFilterActive: false,
      searchActive: false,
      filterType: "",
      filterActive: false,
    };
  },
  computed: {
    ...mapGetters(["GET_RETRIEVED_TERMINALS"]),
    filterAccounts() {
      if (this.GET_RETRIEVED_TERMINALS.data) {
        return this.GET_RETRIEVED_TERMINALS.data;
      } else {
        return [];
      }
    },
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async mounted() {},
  async created() {
    const d = new Date();
    this.dateData = {
      startDate: new Date(d.setDate(d.getDate())).toJSON().split("T")[0],
      endDate: new Date().toJSON().split("T")[0],
      creationDate: new Date().toJSON().split("T")[0],
      startTime: "00:00",
      endTime: this.moment(d).format("HH:mm"),
    };
    this.fetchRecords();
  },
  methods: {
    resetDate() {
      const d = new Date();
      this.dateData = {
        startDate: new Date(d.setDate(d.getDate())).toJSON().split("T")[0],
        endDate: new Date().toJSON().split("T")[0],
        creationDate: new Date().toJSON().split("T")[0],
        startTime: "00:00",
        endTime: this.moment(d).format("HH:mm"),
      };
    },
    async fetchRecords() {
      this.isLoading = true;
      this.currentPage = 1;
      let date = this.dateData;
      let searchActive = this.searchActive;
      let search = this.search;
      let dateFilterActive = this.dateFilterActive;
      let filterType = this.filterType;
      let filterActive = this.filterActive;

      let payload = {
        page: this.currentPage,
        dateFilterActive,
        filterType,
        date,
        searchActive,
        search,
        filterActive,
      };
      let response = await this.$store.dispatch(
        "FETCH_RETRIEVED_TERMINALS",
        payload
      );
      this.accounts = response.data;
      this.isLoading = false;
      if (response.data) {
      }
    },
    async fetchDatedRecords() {
      this.currentPage = 1;
      if (
        this.dateData.startDate > this.dateData.endDate ||
        this.dateData.startDate === this.dateData.endDate
      ) {
        alert(
          "Sorry, you entered an invalid date pair. Please check it and try again."
        );
      } else {
        this.dateFilterActive = true;
        this.currentPage = 1;
        let date = this.dateData;
        let searchActive = this.searchActive;
        let search = this.search;
        let dateFilterActive = this.dateFilterActive;
        let filterType = this.filterType;
        let filterActive = this.filterActive;

        let payload = {
          page: this.currentPage,
          dateFilterActive,
          filterType,
          date,
          searchActive,
          search,
          filterActive,
        };
        try {
          this.isLoading = true;
          let res = await this.$store.dispatch(
            "FETCH_RETRIEVED_TERMINALS",
            payload
          );

          if (res.status) {
            this.isLoading = false;
            if (res.data === null || res.data === undefined) {
              this.isLoading = false;
              alert(
                res.error ||
                  res.message ||
                  "Error: Please check network or contact admin."
              );
              // this.fetchRecords();
            }
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
          }
        } catch (error) {}
      }
    },
    clearDateFilter() {
      this.dateFilterActive = false;
      this.currentPage = 1;
      this.resetDate();
      this.fetchRecords();
    },

    async prevPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let date = this.dateData;
      let searchActive = this.searchActive;
      let search = this.search;
      let dateFilterActive = this.dateFilterActive;
      let filterType = this.filterType;
      let filterActive = this.filterActive;

      let payload = {
        page: currPage - 1,
        dateFilterActive,
        filterType,
        date,
        searchActive,
        search,
        filterActive,
      };
      let res = await this.$store.dispatch(
        "FETCH_RETRIEVED_TERMINALS",
        payload
      );
      if (res.status) {
        this.isLoading = false;
        this.currentPage -= 1;
        if (res.data === null) {
          alert(res.message);
          // this.fetchRecords();
        }
      }
    },
    async nextPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let date = this.dateData;
      let searchActive = this.searchActive;
      let search = this.search;
      let dateFilterActive = this.dateFilterActive;
      let filterType = this.filterType;
      let filterActive = this.filterActive;

      let payload = {
        page: currPage + 1,
        dateFilterActive,
        filterType,
        date,
        searchActive,
        search,
        filterActive,
      };
      let res = await this.$store.dispatch(
        "FETCH_RETRIEVED_TERMINALS",
        payload
      );
      if (res.status) {
        this.isLoading = false;
        this.currentPage += 1;
        if (res.data === null) {
          alert(res.message);
          // this.fetchRecords();
        }
      }
    },
    async downloadCSV() {
      this.isLoading = true;
      let date = this.dateData;
      let searchActive = this.searchActive;
      let search = this.search;
      let dateFilterActive = this.dateFilterActive;
      let filterType = this.filterType;
      let filterActive = this.filterActive;

      let payload = {
        dateFilterActive,
        filterType,
        date,
        searchActive,
        search,
        filterActive,
      };
      try {
        let res = await this.$store.dispatch(
          "DOWNLOAD_RETRIEVED_TERMINALS",
          payload
        );
        this.isLoading = false;

        if (res.status) {
          window.open(res.data, "_blank");
          // alert(res.message);
          this.fetchRecords();
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {}
    },
    async filterFunc() {
      this.isLoading = true;
      this.currentPage = 1;
      let currPage = this.currentPage;
      let filterType = this.filterType;
      let dateFilterActive = this.dateFilterActive;
      let search = this.search;
      this.filterActive = true;

      let payload = {
        page: currPage,
        filterActive: this.filterActive,
        filterType,
        date: this.dateData,
        searchActive: this.searchActive,
        search,
        dateFilterActive,
      };
      try {
        let res = await this.$store.dispatch(
          "FETCH_RETRIEVED_TERMINALS",
          payload
        );
        this.isLoading = false;
        if (res.status) {
          this.isLoading = false;
          if (res.data === null) {
            alert(res.message);
          }
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {}
    },
    clearFilterFunc() {
      this.filterActive = false;
      this.filterType = "";
      this.fetchRecords();
    },
    async searchRecords() {
      this.isLoading = true;
      this.currentPage = 1;
      let currPage = this.currentPage;
      let filterActive = this.filterActive;
      let filterType = this.filterType;
      let dateFilterActive = this.dateFilterActive;
      let search = this.search;
      this.searchActive = true;

      let payload = {
        page: currPage,
        filterActive,
        filterType,
        date: this.dateData,
        searchActive: this.searchActive,
        search,
        dateFilterActive,
      };
      try {
        let res = await this.$store.dispatch(
          "FETCH_RETRIEVED_TERMINALS",
          payload
        );
        this.isLoading = false;
        if (res.status) {
          this.searchActive = true;
          this.isLoading = false;
          if (res.data === null) {
            alert(res.message);
            // this.search = "";
            // this.searchActive =
            // this.fetchRecords();
          }
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {}
    },
    async clearSearchRecords() {
      console.log("Clicked");
      if (this.search !== "") {
        this.search = "";
        this.searchActive = false;
        this.currentPage = 1;
        this.fetchRecords();
      }
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
  position: sticky;
  top: 0;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
